import { ElementShownAnchor } from '@hh.ru/analytics-js';
import editCompanyDescriptionButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/branding/employer_page/edit_company_description_button_click';
import editCompanyDescriptionElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/branding/employer_page/edit_company_description_element_shown';
import Button, { ButtonKind, ButtonAppearance } from 'bloko/blocks/button';
import Gap from 'bloko/blocks/gap';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import RawUserContent from 'src/components/RawUserContent';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    defaultDescription: 'employer.edit.simple.defaultDescription',
    addDescription: 'employer.edit.simple.addDescription',
    editDescription: 'employer.edit.simple.editDescription',
    noDescription: 'employer.no.description.text',
};

interface DefaultDescriptionProps {
    toggleShowDescriptionEditor?: () => void;
    editMode?: boolean;
}

const DefaultDescription: TranslatedComponent<DefaultDescriptionProps> = ({
    trls,
    toggleShowDescriptionEditor,
    editMode,
}) => {
    const descriptionText = useSelector((state) => state.employerInfo.description);
    const hasDescription = !!descriptionText;

    const defaultDescriptionText = editMode ? trls[TrlKeys.defaultDescription] : trls[TrlKeys.noDescription];

    return (
        <>
            {hasDescription ? (
                <Text size={TextSize.Large} data-qa="company-description-text">
                    <RawUserContent content={descriptionText} />
                </Text>
            ) : (
                defaultDescriptionText
            )}
            <VSpacing base={6} />
            {editMode && (
                <div className="company-description-edit-button">
                    <Gap right>
                        <ElementShownAnchor fn={editCompanyDescriptionElementShown}>
                            <Button
                                onClick={() => {
                                    toggleShowDescriptionEditor?.();
                                    editCompanyDescriptionButtonClick();
                                }}
                                data-qa="edit-company-description"
                                kind={ButtonKind.Primary}
                                appearance={hasDescription ? undefined : ButtonAppearance.Outlined}
                            >
                                {hasDescription ? trls[TrlKeys.editDescription] : trls[TrlKeys.addDescription]}
                            </Button>
                        </ElementShownAnchor>
                    </Gap>
                </div>
            )}
            <VSpacing base={6} />
        </>
    );
};

export default translation(DefaultDescription);
