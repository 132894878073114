import { FC } from 'react';

import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

import HeaderBase from 'src/pages/EmployerConstructor/components/HeaderBase';
import HeaderEdit from 'src/pages/EmployerConstructor/components/HeaderEdit';

interface HeaderProps {
    editMode?: boolean;
}

const Header: FC<HeaderProps> = ({ editMode }) => {
    const imagePath = useSelector(
        ({ employerConstructor }) =>
            employerConstructor.images.find((image) => image.pictureId === employerConstructor.pictureId)?.path
    );
    const previewModeWithBranding = useSelector(
        ({ employerConstructor }) => employerConstructor.previewModeWithBranding
    );
    const isZP = useIsZarplataPlatform();

    if (isZP || (!editMode && (!imagePath || !previewModeWithBranding))) {
        return null;
    }
    if (editMode) {
        return <HeaderEdit />;
    }

    return <HeaderBase />;
};

export default Header;
