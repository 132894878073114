import { useCallback, useEffect, Fragment, useState, useMemo } from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import getFileFromEvent, { MixedEvent } from 'src/components/EmployerConstructor/getFileFromEvent';
import resizeImage from 'src/components/EmployerConstructor/resizeImage';
import uploadImage from 'src/components/EmployerConstructor/uploadImage';
import usePictureSettings from 'src/components/EmployerConstructor/usePictureSettings';
import ImageCropPopup, { ImageCropResultHandler } from 'src/components/ImageCropPopup';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import {
    employerConstructorUpdateHeader,
    Status,
    setModalError,
    EMPTY_ERROR_STATE,
} from 'src/models/employerConstructor';
import { PictureType } from 'src/models/employerConstructor/settings';

import HeaderBase from 'src/pages/EmployerConstructor/components/HeaderBase';
import HeaderEditControls from 'src/pages/EmployerConstructor/components/HeaderEditControls';
import HeaderEditPromoUploadButton from 'src/pages/EmployerConstructor/components/HeaderEditPromoUploadButton';

import styles from './employer-constructor-header.less';

const TrlKeys = {
    cropPupupTitle: 'employer.constructor.header.cropimage.title',
    cropPupupDescription: 'employer.constructor.header.cropimage.description',
    cropPupupSave: 'employer.constructor.cropimage.button.save',
    cropPupupCancel: 'employer.constructor.cropimage.button.cancel',
    uploadNew: 'employer.constructor.header.uploadNew',
};

const HeaderEdit: TranslatedComponent = ({ trls }) => {
    const dispatch = useDispatch();
    const pictureId = useSelector((state) => state.employerConstructor.pictureId);
    const resizeStatus = useSelector((state) => state.employerConstructor.resizeStatus);
    const modalError = useSelector((state) => state.employerConstructor.modalError);
    const images = useSelector((state) => state.employerConstructor.images);
    const imageCropSettings = usePictureSettings(PictureType.EmployerPageBackground);
    const [cropPictureId, setCropPictureId] = useState<number | null>(null);
    const { addNotification } = useNotification();

    const cropPicture = useMemo(
        () => images.find((image) => image.pictureId === cropPictureId),
        [cropPictureId, images]
    );
    const resetModalError = useCallback(() => dispatch(setModalError(EMPTY_ERROR_STATE)), [dispatch]);

    /**
     * Чистим ошибка crop модалки при закрытии окна
     */
    useEffect(() => {
        !cropPictureId && resetModalError();
    }, [resetModalError, cropPictureId]);

    const onLoad = useCallback(
        (event: MixedEvent) => {
            const isDragNDrop = Array.isArray(event);
            const imageFile = getFileFromEvent(event);

            dispatch(
                uploadImage(
                    {
                        file: imageFile,
                        pictureType: PictureType.EmployerPageBackground,
                        isDragNDrop,
                        hasAlreadyImage: !!pictureId,
                    },
                    addNotification
                )
            )
                .then((data) => {
                    setCropPictureId(data.pictureId);
                    dispatch(
                        employerConstructorUpdateHeader({
                            resizeStatus: Status.Dirty,
                        })
                    );
                })
                .catch(console.error);
        },
        [addNotification, dispatch, pictureId]
    );

    const removeHeaderImage = useCallback(() => {
        dispatch(
            employerConstructorUpdateHeader({
                pictureId: null,
            })
        );
    }, [dispatch]);
    const onSavePicture: ImageCropResultHandler = useCallback(
        (cropParams) => {
            if (!cropPictureId) {
                return;
            }
            if (cropParams.noChanges) {
                pictureId !== cropPictureId &&
                    dispatch(
                        employerConstructorUpdateHeader({
                            pictureId: cropPictureId,
                        })
                    );
                setCropPictureId(null);
                return;
            }

            resetModalError();
            dispatch(
                resizeImage(
                    {
                        pictureId: cropPictureId,
                        resizeParams: cropParams.absoluteSizes,
                    },
                    setModalError,
                    addNotification
                )
            )
                .then(() => setCropPictureId(null))
                .catch(console.error);
        },
        [resetModalError, dispatch, cropPictureId, addNotification, pictureId]
    );

    return (
        <Fragment>
            <Dropzone multiple={false} disableClick={true} onDrop={onLoad}>
                {({ getRootProps, getInputProps, isDragActive }) => (
                    <div
                        {...getRootProps()}
                        className={classnames(styles.employerConstructorHeaderEdit, {
                            [styles.employerConstructorHeaderEditDrop]: isDragActive,
                        })}
                    >
                        <HeaderBase>
                            {pictureId ? (
                                <HeaderEditControls
                                    allowedMimeTypes={imageCropSettings.allowedMimeTypes}
                                    resizeStatus={resizeStatus}
                                    onLoad={onLoad}
                                    getInputProps={getInputProps}
                                    enhancedToggleCropPopupVisible={() => setCropPictureId(pictureId)}
                                    removeHeaderImage={removeHeaderImage}
                                />
                            ) : (
                                <HeaderEditPromoUploadButton
                                    getInputProps={getInputProps}
                                    resizeStatus={resizeStatus}
                                    allowedMimeTypes={imageCropSettings.allowedMimeTypes}
                                    onLoad={onLoad}
                                />
                            )}
                        </HeaderBase>
                    </div>
                )}
            </Dropzone>
            {!!cropPicture && (
                <ImageCropPopup
                    error={modalError}
                    onClose={() => setCropPictureId(null)}
                    onSave={onSavePicture}
                    visible
                    onDragStop={resetModalError}
                    resizeInProgress={resizeStatus === Status.Fetching}
                    imageCropSettings={{
                        src: cropPicture.originalPath,
                        stateX: cropPicture.selectionLeft,
                        stateY: cropPicture.selectionTop,
                        stateWidth: cropPicture.selectionWidth,
                        stateHeight: cropPicture.selectionHeight,
                        originalWidth: cropPicture.originalWidth,
                        originalHeight: cropPicture.originalHeight,
                        ratio: imageCropSettings.widthHeightRatio,
                        minimumWidth: imageCropSettings.minWidth,
                        minimumHeight: imageCropSettings.minHeight,
                    }}
                    title={trls[TrlKeys.cropPupupTitle]}
                    description={trls[TrlKeys.cropPupupDescription]}
                    save={trls[TrlKeys.cropPupupSave]}
                    cancel={trls[TrlKeys.cropPupupCancel]}
                />
            )}
        </Fragment>
    );
};

export default translation(HeaderEdit);
