import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';

import HeaderTitle from 'src/components/Employer/HeaderTitle';
import EmployerReviewsWidgetSmall from 'src/components/EmployerReviews/WidgetSmall';
import { useIsRussia } from 'src/hooks/useCountries';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

import styles from './employer-constructor-header.less';

const HeaderBase: FC<PropsWithChildren> = ({ children }) => {
    const imagePath = useSelector(
        (state) =>
            state.employerConstructor.images.find((image) => image.pictureId === state.employerConstructor.pictureId)
                ?.path
    );
    const hhcdnHost = useSelector((state) => state.config.hhcdnHost);
    const employerAccepted = useSelector((state) => state.employerInfo.accepted);
    const isRussia = useIsRussia();
    const isZP = useIsZarplataPlatform();

    return (
        <div
            style={
                imagePath
                    ? {
                          backgroundImage: `url(${hhcdnHost}${imagePath})`,
                      }
                    : undefined
            }
            className={classnames(styles.employerConstructorHeader, {
                [styles.employerConstructorHeaderWithoutImage]: !imagePath,
            })}
            data-qa="employer-constructor-header"
        >
            <ColumnsWrapper>
                <Column xs="0" s="0" m="3" l="3" />
                <Column xs="4" s="8" m="9" l="13">
                    <div className={styles.employerConstructorHeaderTitle}>
                        <div className={styles.employerConstructorHeaderTitleWrapper}>
                            <HeaderTitle inverted />
                            {employerAccepted && isRussia && !isZP && (
                                <>
                                    <EmployerReviewsWidgetSmall white />
                                    <VSpacing base={6} xs={3} />
                                </>
                            )}
                        </div>
                    </div>
                </Column>
            </ColumnsWrapper>
            {children}
        </div>
    );
};

export default HeaderBase;
