import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import EmployerSnippet from 'src/components/Employer/EmployerSnippet';
import translation from 'src/components/translation';

import styles from './snippet.less';

const TrlKeys = {
    needBuy: 'employer.constructor.snippet.needBuyRegion',
};

const Snippet: TranslatedComponent = ({ trls }) => (
    <div className={styles.employerConstructorEditSnippet}>
        <EmployerSnippet>{trls[TrlKeys.needBuy]}</EmployerSnippet>
    </div>
);

export default translation(Snippet);
