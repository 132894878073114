import { FC } from 'react';

import StaticImg from 'src/components/StaticImg';
import { useSelectorNonNullable } from 'src/hooks/useSelector';

import styles from './qr-code.less';

const ChatBotQrCode: FC = () => {
    const { page, genericUserType, source } = useSelectorNonNullable((store) => store.chatBot);

    return (
        <StaticImg
            className={styles.qrCode}
            path={`/images/hh/chatbot/${page}/qr-telegram-${genericUserType}-${source}.png`}
            alt={'qr-code-telegram'}
        />
    );
};

export default ChatBotQrCode;
