import { useLayoutEffect, useRef, FC } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import HeaderTitle from 'src/components/Employer/HeaderTitle';
import EmployerPageTabContent from 'src/components/Employer/PageTabs/EmployerPageTabContent';
import EmployerPageTabs from 'src/components/Employer/PageTabs/EmployerPageTabs';
import { usePageTabsContext, EmployerPageTab } from 'src/components/Employer/PageTabs/PageTabsContext';
import EmployerPageTitle, { TileMode } from 'src/components/Employer/PageTitle';
import Sidebar from 'src/components/Employer/Sidebar';
import VacanciesGroupsByRegion from 'src/components/Employer/VacanciesGroupsByRegion';
import SearchFooterButton from 'src/components/Employer/VacancySearch/SearchFooterButton';
import EmployerConstructorHideOnXS from 'src/components/EmployerConstructor/EmployerConstructorHideOnXS';
import Widgets from 'src/components/EmployerConstructor/WidgetsList';
import ConnectReviewsModal from 'src/components/EmployerReviews/ConnectReviewsModal';
import EmployerReviewsWidgetBig from 'src/components/EmployerReviews/WidgetBig';
import EmployerReviewsWidgetSmall from 'src/components/EmployerReviews/WidgetSmall';
import { useCdnHost } from 'src/hooks/useCdnImageHost';
import { useIsRussia } from 'src/hooks/useCountries';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { employerConstructorModifyImage } from 'src/models/employerConstructor';
import { PictureType } from 'src/models/employerConstructor/settings';

import Controls from 'src/pages/EmployerConstructor/components/Controls';
import Header from 'src/pages/EmployerConstructor/components/Header';
import Snippet from 'src/pages/EmployerConstructor/components/Snippet';

import styles from './employer-constructor-content.less';

function calculateSelectionSizes(originalHeight: number, originalWidth: number, ratio: number) {
    const selectionLeft = 50;
    const selectionWidth = originalWidth - selectionLeft * 2;

    const selectionHeight = Math.ceil(selectionWidth / ratio);
    const selectionTop = Math.ceil((originalHeight - selectionHeight) / 2);
    return {
        selectionLeft,
        selectionTop,
        selectionWidth,
        selectionHeight,
    };
}

const EmployerConstructor: FC = () => {
    const editMode = useSelector((state) => state.employerConstructor.editMode);
    const previewModeWithBranding = useSelector((state) => state.employerConstructor.previewModeWithBranding);
    const hasService = useSelector((state) => state.employerConstructor.hasService);
    const canEdit = useSelector((state) => state.employerInfo.canEditDescription && !state.employerPageIsViewMode);
    const employerName = useSelector((state) => state.employerInfo.name);
    const imagePath = useSelector(
        (state) =>
            state.employerConstructor.images.find((image) => image.pictureId === state.employerConstructor.pictureId)
                ?.path
    );
    const vacanciesBlock = useRef(null);
    const isRussia = useIsRussia();
    const isZP = useIsZarplataPlatform();
    const showHeader = ((imagePath && previewModeWithBranding) || editMode) && !isZP;
    const cdnHost = useCdnHost();
    const dispatch = useDispatch();
    const galleryImages = useSelector(({ employerConstructor }) => employerConstructor.images);
    const pictureSettings = useSelector(
        ({ employerConstructorSettings }) => employerConstructorSettings.pictureSettings?.[PictureType.GalleryWidget]
    );
    const employerAccepted = useSelector(({ employerInfo }) => employerInfo.accepted);
    const currentBreakpoint = useBreakpoint();
    const showReviewsWidget = !isZP && employerAccepted;
    const showHeaderReviewsWidget =
        showReviewsWidget &&
        !((editMode || imagePath) && previewModeWithBranding) &&
        [Breakpoint.M, Breakpoint.L].includes(currentBreakpoint);

    const { isEmployerTabsExp } = usePageTabsContext();

    useLayoutEffect(() => {
        if (isZP) {
            galleryImages.forEach((image) => {
                if (image.originalWidth === 0 || image.originalHeight === 0) {
                    const imageElement = new Image();
                    imageElement.src = `${cdnHost}${image.originalPath}`;
                    imageElement.addEventListener('load', (event) => {
                        const imageTarget = event.target as HTMLImageElement;
                        const naturalWidth = imageTarget.naturalWidth;
                        const naturalHeight = imageTarget.naturalHeight;

                        dispatch(
                            employerConstructorModifyImage({
                                ...image,
                                originalWidth: naturalWidth,
                                originalHeight: naturalHeight,
                                originalPath: `${cdnHost}${image.originalPath}`,
                                ...calculateSelectionSizes(
                                    naturalHeight,
                                    naturalWidth,
                                    pictureSettings?.widthHeightRatio || 1
                                ),
                            })
                        );
                        imageElement.remove();
                    });
                } else {
                    dispatch(
                        employerConstructorModifyImage({
                            ...image,
                            originalPath: `${cdnHost}${image.originalPath}`,
                        })
                    );
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <EmployerPageTitle
            mode={editMode && canEdit ? TileMode.Constructor : TileMode.View}
            employerName={employerName}
        >
            <Header editMode={editMode} />
            {!showHeader && (
                <EmployerConstructorHideOnXS>
                    <Gap bottom />
                </EmployerConstructorHideOnXS>
            )}
            <ColumnsWrapper>
                <Column xs="4" s="8" m="3" l="3">
                    <div
                        className={classnames({
                            'employer-sidebar-constructor-wrapper': showHeader,
                        })}
                    >
                        <Sidebar
                            withoutColors={!previewModeWithBranding}
                            showConstructorGuideLink={!isZP}
                            editMode={canEdit && editMode}
                            hideTitle={showHeader}
                        />
                    </div>
                </Column>
                <Column xs="4" s="8" m="9" l="11">
                    {canEdit && editMode && !hasService && !isZP && <Snippet />}
                    <div className={styles.employerConstructorTitleWithoutHeader}>{!showHeader && <HeaderTitle />}</div>
                    {showHeaderReviewsWidget && <EmployerReviewsWidgetSmall />}
                    {!editMode && <EmployerPageTabs />}
                    <EmployerPageTabContent tab={EmployerPageTab.Description}>
                        <Widgets editMode={canEdit && editMode} />
                        {isEmployerTabsExp && <Gap top />}
                        {showReviewsWidget && isEmployerTabsExp && <EmployerReviewsWidgetBig />}
                    </EmployerPageTabContent>
                    {!editMode && (
                        <EmployerPageTabContent tab={EmployerPageTab.Vacancies}>
                            <div ref={vacanciesBlock}>
                                {isEmployerTabsExp && <Gap top />}
                                <VacanciesGroupsByRegion />
                            </div>
                        </EmployerPageTabContent>
                    )}
                    {showReviewsWidget && !isEmployerTabsExp && <EmployerReviewsWidgetBig />}
                </Column>
            </ColumnsWrapper>
            <SearchFooterButton />
            <Gap top />
            {canEdit && <Controls />}
            {isRussia && !isZP && <ConnectReviewsModal />}
        </EmployerPageTitle>
    );
};

export default EmployerConstructor;
