import { useState } from 'react';
import { createPortal } from 'react-dom';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import actionBarButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/design_system/action_bar_button_click';
import actionBarElementShown from '@hh.ru/analytics-js-events/build/xhh/common/design_system/action_bar_element_shown';
import { ActionBar, Button, useBreakpoint, Layer, LayerName } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import LocalStorageWrapper from 'bloko/common/storage/LocalStorageWrapper';

import translation from 'src/components/translation';

const LC_WANT_SAME_CLICKED_KEY = 'hh-tempexp_26879-key';

function hasIdInStorage(keyPrefix: 'vacancyId' | 'employerId', id: string) {
    const lcKey = `${LC_WANT_SAME_CLICKED_KEY}:${keyPrefix}`;
    const item = LocalStorageWrapper.getItem(lcKey);

    if (item === null) {
        return false;
    }
    const ids = item.split(',');
    return ids.includes(id);
}

function setIdInStorage(keyPrefix: string, id: string) {
    const lcKey = `${LC_WANT_SAME_CLICKED_KEY}:${keyPrefix}`;
    const item = LocalStorageWrapper.getItem(lcKey);

    if (item === null) {
        LocalStorageWrapper.setItem(lcKey, id);
    } else {
        LocalStorageWrapper.setItem(lcKey, `${item},${id}`);
    }
}

const TrlKeys = {
    wantSameBranding: 'employer.wantSame.wantSameBranding',
    close: 'employer.wantSame.close',
};

interface ContentProps {
    keyPrefix: 'vacancyId' | 'employerId';
    id: string;
}

const Content: TranslatedComponent<ContentProps> = ({ trls, keyPrefix, id }) => {
    const hasId = hasIdInStorage(keyPrefix, id);
    const [visible, setVisible] = useState(!hasId);

    const { isMobile } = useBreakpoint();

    if (!visible) {
        return null;
    }

    const hhtmSource = keyPrefix === 'vacancyId' ? 'vacancy' : 'employer';

    return createPortal(
        <Layer layer={LayerName.Tau}>
            <div style={{ position: 'relative' }}>
                <ActionBar
                    type="page"
                    primaryActions={[
                        <ElementShownAnchor
                            fn={actionBarElementShown}
                            key="WANT_BRANDING"
                            hhtmSource={hhtmSource}
                            analyticsId="want_branding_too"
                        >
                            <Button
                                data-qa="want-the-same-button"
                                mode="primary"
                                Element={Link}
                                target="_blank"
                                to="/price/branding"
                                style="accent"
                                stretched
                                onClick={() => {
                                    setVisible(false);
                                    setIdInStorage(keyPrefix, id);
                                    actionBarButtonClick({
                                        title: 'Хочу такое брендирование',
                                        index: 0,
                                        buttonStackLength: 1,
                                        hhtmSource,
                                        analyticsId: 'want_branding_too',
                                    });
                                }}
                            >
                                {trls[TrlKeys.wantSameBranding]}
                            </Button>
                        </ElementShownAnchor>,
                    ]}
                    secondaryActions={
                        !isMobile
                            ? [
                                  <Button
                                      data-qa="want-the-same-close-button"
                                      key="CLOSE"
                                      mode="tertiary"
                                      style="accent"
                                      onClick={() => {
                                          actionBarButtonClick({
                                              title: 'Закрыть',
                                              index: 0,
                                              buttonStackLength: 1,
                                              hhtmSource,
                                              analyticsId: 'want_branding_too',
                                          });
                                          setVisible(false);
                                          setIdInStorage(keyPrefix, id);
                                      }}
                                  >
                                      {trls[TrlKeys.close]}
                                  </Button>,
                              ]
                            : undefined
                    }
                />
            </div>
        </Layer>,
        document.body
    );
};

export default translation(Content);
