import { EmployerConstructorState, EmployerConstructorWidget } from 'src/models/employerConstructor';

export const SHOW_CONNECT_REVIEWS_MODAL_EVENT = 'HH-ERF-SHOW_CONNECT_REVIEWS_MODAL';

export const openConnectReviewsModal = (
    isRussia: boolean,
    employerConstructor: EmployerConstructorState,
    validWidgets: EmployerConstructorWidget[]
): void => {
    const isCustomProfile =
        hasPicture(employerConstructor) || isSidebarHasChangedColors(employerConstructor) || hasWidgets(validWidgets);

    if (isRussia && isCustomProfile) {
        window.dispatchEvent(new CustomEvent(SHOW_CONNECT_REVIEWS_MODAL_EVENT));
    }
};

function hasPicture(employerConstructor: EmployerConstructorState) {
    return employerConstructor.pictureId !== null;
}
function isSidebarHasChangedColors(employerConstructor: EmployerConstructorState) {
    return Object.keys(employerConstructor.sidebarColors).length > 0;
}
function hasWidgets(validWidgets: EmployerConstructorWidget[]) {
    return validWidgets.length > 0;
}
