import { useEffect } from 'react';

import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru } from 'src/hooks/useSites';
import { UserType } from 'src/models/userType';
import { UXFeedback } from 'src/utils/uxfeedback';

const useSendUxFeedbackOnEmployerView = (): void => {
    const isHHRu = useIsHhru();
    const isTargetUser = useSelector(({ userType }) => [UserType.Anonymous, UserType.Applicant].includes(userType));
    const hhtmFrom = useSelector((state) => state.analyticsParams.hhtmFrom);
    let survey: 'resume_view_history' | 'vacancy' | 'others' | undefined;
    switch (hhtmFrom) {
        case 'resume_view_history':
            survey = 'resume_view_history';
            break;
        case 'vacancy':
            survey = 'vacancy';
            break;
        case '':
        case 'employers_list':
            survey = 'others';
            break;
        default:
            break;
    }

    useEffect(() => {
        if (isHHRu && isTargetUser && survey) {
            setTimeout(() => {
                UXFeedback.sendEvent(`purpose_of_visit_from_${survey}`);
            }, 3000);
        }
    }, [isHHRu, isTargetUser, survey]);
};

export default useSendUxFeedbackOnEmployerView;
